export const getUserNameInitials = (name) => {
    const names = name.split(' ');
    if(names.length === 1) {
        return names[0].charAt(0).toUpperCase();
    }
    const firstName = names[0];
    const lastName = names[names.length - 1];
    const initials = firstName.charAt(0) + lastName.charAt(0);
    return initials.toUpperCase();
}
